import React, { useState, useEffect } from 'react'
import { TabContent, TabPane } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import {
    ModalLayout,
    SelectUser,
    Button
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'
import useCheckInStudentModal from '../../IssueCheckIn/Modal/CheckInStudent/useCheckInStudentModal'
import useUsersQuery from '../../Model/useGetStudents'
import { ordenateUserArray } from '../../../../../Parent/Utils/ordenateUsersArray'

const MessageModal = (props) => {
    const { type, isOpen, setOpen } = props
    const [activeTab, setActiveTab] = useState('show')
    const { open: openCheckInStudent, close: closeCheckInStudent } = useCheckInStudentModal()
    const [selectUser, setSelectUser] = useState({})
    let userData = {}
    const toggle = () => {
        setOpen(!isOpen)
        setSelectUser({})

    }
    const [usersQuery, { data: dataUsers, loading: loadingUsers }] = useUsersQuery()
    const { translate } = useTranslation()
    const descriptionType = {
        checkinPlans: translate("dashboard.issueCheckIn.checkinPlan"),
        homework: translate("dashboard.issueCheckIn.homework"),
        pendingLessons: translate("dashboard.issueCheckIn.missing"),
        missingLessons: translate("dashboard.issueCheckIn.pending"),
    }
    const text = {
        checkinPlans: "Check In Plan",
        homework: 'Missing Homework',
        pendingLessons: 'Pending Lessons',
        missingLessons: 'Missing Lessons',
    }

    const typeReason = {
        checkinPlans: 'checkinPlan',
        homework: 'homework',
        pendingLessons: 'pending',
        missingLessons: 'missing',
    }

    const refetch = () => { }

    const openSendMessage = () => {
        openCheckInStudent(() => {
            closeCheckInStudent()
            refetch()
        }, userData, typeReason[type], descriptionType[type], text[type])
    }



    useEffect(() => {
        usersQuery({
            variables: {
                role: 'STUDENT',
                limit: 0,
                showInactive: false
            }
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (loadingUsers) {
            setActiveTab('loading')
        } else {
            setActiveTab('show')
        }
    }, [loadingUsers])

    useEffect(() => {
        // eslint-disable-next-line
        userData = dataUsers?.allUsersFilter?.docs?.find(user => user.id === selectUser)
    }, [selectUser])
    
    

    return (
        <ModalLayout
            className='modal-colored modal-centered fixed-head-modal'
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <div className={'m-0 p-0 mt-4 mb-3 d-flex flex-column'}>
                    <div className={'d-flex flex-row align-items-center mb-5'}>
                        <span className='h2 font-weight-bold'>{translate("dashboard.issueCheckIn.selectStudentTitle")}</span>
                    </div>
                </div>
            }
            disabledScrollAnimation
            underHead={
                <div className='d-flex flex-column w-100 mb-2'>

                    <div className='d-flex flex-row w-100'>
                    <span className='text-gray'>{translate("dashboard.issueCheckIn.selectStudentBody")}</span>
                    </div>
                </div>
            }
        >
            <div className={'m-0 p-0 d-flex flex-column'}>
                <div className={''} />
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'show'} key={'show'}>
                        <div className='d-flex flex-column'>
                            <div className='d-flex flex-row mb-4 mt-1'>
                                {
                                    dataUsers?.allUsersFilter?.docs?.length > 0 &&
                                    <SelectUser
                                        className={'my-2 w-100'}
                                        activeUsers={dataUsers?.allUsersFilter?.docs}
                                        selectUser={selectUser}
                                        setSelectUser={setSelectUser}
                                        ordenateUserArray={ordenateUserArray}
                                    />
                                }
                            </div>
                        </div>
                        <Button
                            color='primary'
                            activity={false}
                            disabled={selectUser?.length === 0}
                            className='col-6 mt-2'
                            label={translate("dashboard.issueCheckIn.selectStudentButton")}
                            onClick={()=> {
                                //funcion con delay para que openSendMessage se ejecute despues de que se cierre el modal
                                openSendMessage()
                                setTimeout(() => {
                                    toggle()
                                }, 1000)  
                                
                                
                            }
                        }
                            style={{ borderRadius: `${'16px 16px 16px 16px'}` }}
                        />
                    </TabPane>


                    <TabPane tabId={'loading'} key={'loading'}>
                        <div className='d-flex flex-column'>
                            <Skeleton height={44} width={320} count={1} />
                        </div>
                    </TabPane>

                </TabContent>
            </div>
        </ModalLayout>
    )
}

export default MessageModal