import React, { useState, useEffect, useMemo, useCallback } from 'react'
import {
  Modal,
  IconClose,
  Input,
  CheckboxOption,
  IconEdit,
  SelectOption,
  StickyButton
} from '@Knowledge-OTP/znk-ui-components'
import { FormGroup, Collapse } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import makeUpLesson from '../../../../Model/Lessons/MakeUpLesson/index.js'
import LessonDetails from '../../Components/LessonDetails'
import Educator from '../NewLesson/Educator'
import Student from '../NewLesson/Student'
import moment from 'moment'
import useTranslation from '../../../../i18n/useTranslation'
import {
  parseOcurrencesToMutationMakeUp,
  closeCalendarConflict,
} from '../utils'
import useScheduledConflict from '../../Components/ScheduledConflict/useScheduledConflict.js'
import TimeRange from '../TimeRange'

const MakeUpLesson = () => {
  const dispatch = useDispatch()
  const { isOpen, dataLesson, onChange } = useSelector((state) => state.modal.plan.makeUpLesson)
  const { translate } = useTranslation()
  const [form, setForm] = useState({
    date: null,
    timeStart: null,
    timeEnd: null,
    reason: null,
    payedu: false,
    charge: false,
    comment: null
  })
  const resetForm = () => {
    // reinicia el formulario
    setForm({
      date: null,
      timeStart: null,
      timeEnd: null,
      reason: null,
      payedu: false,
      charge: false,
      comment: null
    })
  }
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.makeUpLesson',
      data: {
        isOpen: !isOpen,
        dataLesson
      }
    })
  }

  const parseVariablesFromOcurrences = useCallback(
    (dataCalendar) => {
      const solveLesson = dataCalendar?.ocurrences[0]
      return parseOcurrencesToMutationMakeUp(
        dataCalendar,
        solveLesson.resolve === 'newBlock'
          ? parseDataToMutation(form, dataLesson?.id || '')
          : solveLesson.resolve === 'force'
            ? // scheduled anyway
            {
              lessonId: dataLesson?.id,
              makeUpInput: {
                ...parseDataToMutation(form, dataLesson?.id || '').makeUpInput,
                forceIgnoreUnavailability: true
              }
            }
            : // Dejar pendiente
            {
              lessonId: dataLesson?.id,
              makeUpInput: {
                ...parseDataToMutation(form, dataLesson?.id || '').makeUpInput,
                setAsPending: true
              }
            }
      )
      // eslint-disable-next-line
    }, [form]
  )
  const {open: openCalendarConflict, handleMutationResponse} = useScheduledConflict()
  const { submit: makeUpLessonMutation, loading: loadMakeUpLesson } = makeUpLesson(
    (result) => {
      handleMutationResponse(
        result?.data?.makeUpLesson,
        (ocu) => {
          openCalendarConflict(ocu, (dataCalendar) => {
            //recursividad
            const variables = parseVariablesFromOcurrences(dataCalendar)
            makeUpLessonMutation(variables)
          })
        },// on ocurrences
        () => {},// on error
        (newLessons) => {
          closeCalendarConflict(dispatch)
          resetForm()
          if(onChange) onChange(newLessons[0]) // viene una sola lección siempre
          toggle()
        } // on completed
      )
    },
    (e) => {
      console.error(e)
      alert('error')
    }
  )


  const [showComment, setShowComment] = useState(false)

  useEffect(() => {
    if ((dataLesson?.startDate && dataLesson?.endDate) || isOpen) {
      setForm({
        ...form,
        // reinicia el formulario
        date: moment(dataLesson.startDate).format('YYYY-MM-DD'),
        timeStart: moment(dataLesson.startDate).format('HH:mm'),
        timeEnd: moment(dataLesson.endDate).format('HH:mm'),
        reason: null,
        payedu: false,
        charge: false,
        comment: null,
        educator: dataLesson?.substitute || dataLesson?.educator || {},
        student: dataLesson?.student || dataLesson?.studentPlan || dataLesson?.studentGroup || {},
        service: dataLesson?.service || {},
        topic: dataLesson?.individualTopic || dataLesson?.planTopic || dataLesson?.groupTopic || {},
        locationLesson: dataLesson?.location?.type || null,
        locationLessonLink: dataLesson?.location?.linkStudent || dataLesson?.location?.linkEducator || null
      })
    }
  // eslint-disable-next-line
  }, [dataLesson, isOpen])

  const objReasons = {
    'tecdifstu': {id: 'tecdifstu', text: `${translate('modal.makeuplesson.technicaldiff')} (${translate('modal.infolesson.student')})` },
    'tecdifedu': {id: 'tecdifedu', text: `${translate('modal.makeuplesson.technicaldiff')} (${translate('modal.infolesson.educator')})` },
    'tecdifzin': {id: 'tecdifzin', text: `${translate('modal.makeuplesson.technicaldiff')} (Zinkerz)` },
    'edunoshow': {id: 'edunoshow', text: translate('modal.makeuplesson.educatornoshow') },
    'emergency': {id: 'emergency', text: translate('modal.makeuplesson.emergency') }
  }
  const parseDataToMutation = (data, lessonId) => {
    const date = moment(data.date).set({
      hour: parseInt(data?.timeStart.split(':')[0]),
      minute: parseInt(data?.timeStart.split(':')[1]),
      second: 0,
      millisecond: 0
    })
    const endDate = moment(data?.date).set({
      hour: parseInt(data?.timeEnd.split(':')[0]),
      minute: parseInt(data?.timeEnd.split(':')[1]),
      second: 0,
      millisecond: 0
    })
    /**
     *  educatorUserId: ID!
        location: LocationLessonInput!
        lostReason: String!
        payEducator: Boolean!
        chargeStudent: Boolean!
        comment: String!
        date: DateTime!
        startTime: String
        endTime: String
        lessonDuration: Float
     * **/
    return {
      lessonId,
      makeUpInput: {
        educatorUserId: data?.educator?.educatorUserId,
        lostReason: objReasons[data?.reason || 'emergency'].text,
        lostReasonKey: objReasons[data?.reason || 'emergency'].id || data?.reason,
        payEducator: data?.payedu || false,
        chargeStudent: data?.charge || false,
        comment: data?.comment || '',
        date: date,
        startTime: data?.timeStart,
        endTime: data?.timeEnd,
        lessonDuration: moment.duration(moment(endDate).diff(date)).asMinutes(),
        location: {
          type: data?.locationLesson || '',
          linkStudent: data?.locationLessonLink || '',
          linkEducator: data?.locationLessonLink || ''
        },
        setAsPending: false,
        forceIgnoreUnavailability: false
      }
    }
  }

  const [detailsOpen, setDetailsOpen] = useState(false)
  const toggleCollapse = () => { setDetailsOpen(!detailsOpen) }

  const validation = useMemo(() => {
    let valid = true
    /**
     *date: null,
      timeStart: null,
      timeEnd: null,
      reason: null,
      payedu: false,
      charge: false,
      comment: null
     * **/
    valid =
      (form?.date !== null && form?.date !== undefined) &&
      (form?.timeStart !== null && form?.timeStart !== undefined) &&
      (form?.timeEnd !== null && form?.timeEnd !== undefined) &&
      (form?.locationLesson !== null && form?.locationLesson !== undefined && form?.locationLesson !== '') &&
      (form?.reason !== null && form?.reason !== undefined)
    return valid
  }, [form])


  

  return (
    <Modal
      buttonLabel={null}
      className='modal-colored modal-centered fixed-head-modal'
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header p-6 d-flex flex-row justify-content-between'>
          <span className='h2 font-weight-bold'>{translate('modal.infolesson.makeuplesson')}</span>
          <IconClose onClick={toggle} />
        </div>
      }
    >
      <div className='m-0 px-6'>
        <FormGroup className='w-100 d-flex flex-column'>
          <div className='w-100 d-flex flex-column'>
            <span className='w-100 p-0 text-gray'>
              {translate('modal.newlesson.date')}
            </span>
            <Input
              type='date' name='date' id='date'
              value={form.date}
              onChange={(e) => {
                setForm({
                  ...form,
                  date: e.target.value
                })
              }}
            />
          </div>
          <TimeRange setForm={setForm} form={form} />
          {/**<div className='mx-0 my-3 p-0 d-flex flex-row'>
            <div className='w-50 d-flex flex-column'>
              <span className='p-0 text-gray'>
                {translate('modal.newlesson.start')}
              </span>
              <Input
                className='noRight'
                type='time' name='timeStart' id='timeStart'
                value={form.timeStart}
                onChange={(e) => {
                  setForm({
                    ...form,
                    timeStart: e.target.value
                  })
                }}
              />
            </div>
            <div className='w-50 d-flex flex-column'>
              <span className='p-0 text-gray'>
                {translate('modal.newlesson.end')}
              </span>
              <Input
                className='noLeft'
                type='time' name='timeEnd' id='timeEnd'
                value={form.timeEnd}
                onChange={(e) => {
                  setForm({
                    ...form,
                    timeEnd: e.target.value
                  })
                }}
              />
            </div>
          </div>**/}
          <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
            {translate('modal.makeuplesson.reason')}
          </label>
          <SelectOption
            className='col-12'
            name='reason'
            showNotSelected
            onChange={(e) => { 
              setForm({ ...form, reason: e }) }}
            changeLabel={translate('modal.makeuplesson.changereason')}
            changeFunction={() => { setForm({ ...form, reason: null }) }}
            options={Object.values(objReasons)}
            value={form.reason}
          />
          <div className='d-flex flex-row mt-5 ml-2'>
            <CheckboxOption
              value={form.payedu} onChange={(e) => {
                console.log(e)
                setForm({ ...form, payedu: !form.payedu })
              }}
            />
            <span>{translate('modal.makeuplesson.payeducator')}</span>
          </div>
          <div className='d-flex flex-row mt-5 ml-2'>
            <CheckboxOption
              value={form.charge} onChange={(e) => {
                console.log(e)
                setForm({ ...form, charge: !form.payedu })
              }}
            />
            <span>{translate('modal.makeuplesson.chargestudent')}</span>
          </div>
          {
            !showComment
              ? (
                <div className='d-inline flex-row mt-5 ml-2' onClick={() => { setShowComment(true) }}>
                  <IconEdit className='mr-2 mt-1 mb-2 ml-0' size={14} />
                  <span>{translate('modal.makeuplesson.addcomment')}</span>
                </div>
              )
              : (
                <div className='w-100'>
                  <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
                    {translate('modal.makeuplesson.comment')}
                  </label>
                  <textarea
                    onChange={(e) => {
                      setForm({ ...form, comment: e.target.value })
                    }} value={form.comment} placeholder={translate('modal.makeuplesson.typehere')} className='znk-input w-100 p-3' style={{ height: '150px' }}
                  />
                </div>
              )
          }
        </FormGroup>
        <label
          className='btn-link w-100 my-5'
          onClick={toggleCollapse}
        >
          {detailsOpen ? translate('modal.makeuplesson.hidelessondetails') : translate('modal.makeuplesson.viewlessondetails')}
        </label>
        <Collapse isOpen={detailsOpen}>
          <div className='m-0 p-0 my-3'>
            <LessonDetails form={form} setForm={setForm} typeAndlocation={`${dataLesson.__typename || ''} | ${dataLesson?.location?.type === 'hangouts' ? 'Meets' : dataLesson?.location?.type || 'No defined'}`} />
            <Educator className='mt-3' form={form} setForm={setForm} />
            <Student className='mt-3' form={form} setForm={setForm} cantChange />
          </div>
        </Collapse>
        <StickyButton
          disabled={!validation}
          onClickButtonFunction={() => {
            makeUpLessonMutation(
              parseDataToMutation(
                form,
                dataLesson?.id || ''
              )
            )
          }}
          activity={loadMakeUpLesson}
          color='#696CFF'
          className='w-100 mb-6'
          size='medium'
          label={translate('modal.newlesson.createlesson')}
        />
      </div>
    </Modal>
  )
}

export default MakeUpLesson
