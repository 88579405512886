import React from 'react'
import {
  ModalScroll,
  IconClose,
  IconOptions,
  useWindowDimensions, IconLesson
} from '@Knowledge-OTP/znk-ui-components'
import InfoModal from './scheduled'
import InfoModalDraftSent from './draft'
import { useDispatch, useSelector } from 'react-redux'
import OptionsPlan from './OptionsPlan'
import moment from 'moment-timezone'
import useTranslation from '../../../i18n/useTranslation'
const PlanModal = (props) => {
  const { refetch = () => {} } = props
  const { isOpen, ...otherProps } = useSelector((state) => state.modal.plan.seeInfo)
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.seeInfo',
      data: { isOpen: !isOpen, ...otherProps }
    })
  }
  const { breakWidth } = useWindowDimensions()
  const { thisService, info } = otherProps
  const topicKeys = thisService
    ? thisService.topics.reduce((obj, item) => {
      obj[item.id] = item
      return obj
    }, {})
    : {}
  const parsePlan = (plan) => {
    const startTime = (plan.parts || plan.fixedParts || plan.PartsSchedule).reduce(
      (num, item) => {
        return new Date(item.from.split('T')[0]).getTime() < num ? new Date(item.from.split('T')[0]).getTime() : num
      },
      Number.POSITIVE_INFINITY
    )
    const endTime = (plan.parts || plan.fixedParts || plan.PartsSchedule).reduce(
      (num, item) => (new Date(item.to.split('T')[0]).getTime() > num ? new Date(item.to.split('T')[0]).getTime() : num),
      Number.NEGATIVE_INFINITY
    )
    return {
      ...plan,
      startTime: moment(startTime).toISOString().split('T')[0],
      endTime: moment(endTime).toISOString().split('T')[0],
      parts: plan.parts || plan.fixedParts || plan.PartsSchedule
    }
  }
  const planInfo = info ? parsePlan(info) : null
  const planName = `${planInfo?.name && planInfo?.name}`
  const { translate } = useTranslation()
  const modalClass = planInfo
    ? ((planInfo.status === 'sent' || planInfo.status === 'draft') ? 'pending-modal'
      : (planInfo.status === 'pause' || planInfo.status === 'cancel' ? 'pause-modal' : 'upcoming-modal'))
    : 'upcoming-modal'

  const groupInfo = info ? info.groupPerWeekInfo || info.groupFixedInfo : null

  const RenderHeaderOptions = () => {
    return (
      <div className='m-0 p-0 d-flex align-items-center'>
        <OptionsPlan
          refetch={refetch}
          toggleModal={toggle}
          dataPlan={info}
          className='mr-3'
          classNameModal='modal-corner-top-right'
        >
          <IconOptions size={20} className='m-0 p-0' style={{ transform: 'rotate(90deg)' }} />
        </OptionsPlan>
        <IconClose className={'text-cancel'} size={20} onClick={toggle} />
      </div>
    )
  }

  return (
    <ModalScroll
      className={`modal-${
          breakWidth === 'SM' ? 'botbar' : 'sidebar modal-desktop-half'
        } modal-colored ${modalClass}`}
      modalScrollClassName={'h-100'}
      isOpen={isOpen}
      toggle={toggle}
      MinHead={
        <div className='d-flex flex-row p-4 justify-content-between w-100'>
            <div className={'m-0 p-0 d-flex flex-column'}>
              <span
                style={
                  (planInfo && planInfo.status === 'cancel')
                    ? { textDecoration: 'line-through' }
                    : {}
                }
                className={`h2 ${planInfo && (planInfo.status === 'pause' || planInfo.status === 'cancel') ? '' : 'text-light'}`}
              >{planName}
              </span>
              {planInfo && (
                <span className={`h45 ${planInfo && (planInfo.status === 'pause' || planInfo.status === 'cancel') ? '' : 'text-light'}`}>
                  {moment(planInfo.startTime).format('MMM D, YYYY')} to {moment(planInfo.endTime).format('MMM D, YYYY')}
                </span>
              )}
            </div>
            <RenderHeaderOptions/>
        </div>    
      }
      Head={
        <div className='modal-header d-flex flex-column mx-4 mt-4 p-0 mb-2'>
          <span className='d-flex w-100 px-4 align-items-center'>
            <span className='flex-grow-1'>
              <IconLesson className={`${planInfo && (planInfo.status === 'pause' || planInfo.status === 'cancel') ? '' : 'text-light'} mr-2`} />
              {translate('misc.livePrep')}
            </span>
            <RenderHeaderOptions/>
          </span>
          <div className='d-flex flex-column px-4 pb-4'>
            <span
              style={
                (planInfo && planInfo.status === 'cancel')
                  ? { textDecoration: 'line-through' }
                  : {}
              }
              className={`h2 mt-7 ${planInfo && (planInfo.status === 'pause' || planInfo.status === 'cancel') ? '' : 'text-light'}`}
            >{planName}
            </span>
            {planInfo && (
              <span className={`h45 ${planInfo && (planInfo.status === 'pause' || planInfo.status === 'cancel') ? '' : 'text-light'}`}>
                {moment(planInfo.startTime).format('MMM D, YYYY')} to {moment(planInfo.endTime).format('MMM D, YYYY')}
              </span>
            )}
            <span className={`my-2 font-weight-light h45 ${planInfo && (planInfo.status === 'pause' || planInfo.status === 'cancel') ? '' : 'text-transparent'}`}>
              {`${planInfo && planInfo.testDate ? translate('plans.testDate') : translate('plans.fixedParts')} | ${planInfo && planInfo.location ? translate(`misc.location.${planInfo.location.type === 'hangouts' ? 'meets' : planInfo.location.type}`) : '-'}`}
            </span>
            <span className={`my-1 font-weight-light h45 ${planInfo && (planInfo.status === 'pause' || planInfo.status === 'cancel') ? '' : 'text-transparent'}`}>
              {planInfo ? `ID: ${planInfo.id}` : ''}
            </span>
          </div>
        </div>
      }
    >
      {planInfo ? (
        (planInfo.status === 'draft' || planInfo.status === 'sent')
          ? <InfoModalDraftSent groupInfo={groupInfo} info={planInfo} status={planInfo.status} topicKeys={topicKeys} />
          : <InfoModal refetch={refetch} groupInfo={groupInfo} info={planInfo} status={planInfo.status} topicKeys={topicKeys} />
      ) : null}
    </ModalScroll>
  )
}

export default PlanModal
