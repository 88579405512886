/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import {
  Modal,
  IconClose,
  IconOptions,
  useWindowDimensions,
  IconLesson,
  DropDown,
  ListItem,
  Avatar,
  IconArrowRight,
  IconArrowUp,
  Button
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/client'
import useTranslation from '../../../../../i18n/useTranslation'
import PartScheduleView from './Components/PartScheduleView'
import formatNumber from '../../../../../utils/formatNumber'
import './index.css'
import Skeleton from 'react-loading-skeleton'
import { redirectToSendOnePlan, redirectToSendAllPlan } from '../../../Fields/utils'

const ViewGroupPlan = (props) => {
  const { educatorsBase, services } = props
  const { isOpen, ...otherProps } = useSelector((state) => state.modal.groupPlans.viewSingle)
  const [planInfo, setPlanInfo] = useState(null)
  const [currentService, setCurrentService] = useState(null)
  const dispatch = useDispatch()
  const { info } = otherProps
  const parsePlan = (plan) => {
    const startTime = (plan.parts || plan.fixedParts || plan.PartsSchedule).reduce(
      (num, item) => {
        return new Date(item.from.split('T')[0]).getTime() < num ? new Date(item.from.split('T')[0]).getTime() : num
      },
      Number.POSITIVE_INFINITY
    )
    const endTime = (plan.parts || plan.fixedParts || plan.PartsSchedule).reduce(
      (num, item) => (new Date(item.to.split('T')[0]).getTime() > num ? new Date(item.to.split('T')[0]).getTime() : num),
      Number.NEGATIVE_INFINITY
    )
    return {
      ...plan,
      startTime: moment(startTime).toISOString().split('T')[0],
      endTime: moment(endTime).toISOString().split('T')[0],
      parts: plan.parts || plan.fixedParts || plan.PartsSchedule
    }
  }
  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()
  const [getGroupInfo, { data }] = useLazyQuery(
    gql`query($planId: ID!){
      getGroupPlan(planId: $planId) {
        id
        name
        cancelDate
        pauseDate
        resumeDate
        studentPlans {
          studentId
          planId
          planStatus
          registerDate
          firstName
          lastName
        }
        serviceId
        lessonIds
        paymentItemIds
        paymentItemsConfig {
          amount
          percentDiscount 
          amountWithoutDiscount
          date
          description
          type
        }
        recommendedEducators {
          educatorUserId
          firstName
          lastName
          rate
          isManualRate
          topicId
          teachingType
          lessonLink
        }
        selectedEducators {
          educatorUserId
          firstName
          lastName
          rate
          isManualRate
          topicId
          lessonLink
        }
        status
        kind
        createdAt
        updatedAt  
        isFirstPlan
        typePlanPricing
        pricingRate
        groupPricingRate
        location {
          type
          linkEducator
          linkStudent
        }
        groupId
        ... on GroupPLan {
          testDate
          PartsSchedule {
            name
            from
            to
            scheduleType
            lessonsPerWeekGroup {
              topicId
              educatorId
              day
              duration
              hour
              totalLessons
            }
            lessonsPerWeekIndividual {
              topicId
              lessonsPerWeek
              lockLessons
              totalLessons
            }
          }
          studentPlans {
            studentId
            planId
            registerDate
            planStatus
          }
          lessonGroupIds
        },
        childPlans {
          id
          studentUser {
            studentUserId
            firstName
            lastName
            rate
            isManualRate
          }
          kind
          name
          status
          ... on LessonsPerWeekPlan {
            paymentItemsConfig {
              amount
            }
            invoices {
              id
              amount
              paymentItemIds
              paymentItems {
                ...on PlanPaymentItem {
                  amount
                  status
                  planId
                }
              }
              amounts {
                subTotal
                amount
                taxAmount
              }
              status
            }
            parts {
              from
              to
              totalLessons
              name
              lessonsPerWeek
              lessonPerWeekPerTopic {
                topicId
                lessonsPerWeek
                lockLessons
              }
            }
          }
          ... on LessonsFixedPlan {
            paymentItemsConfig {
              amount
            }
            invoices {
              amount
              amount
              paymentItemIds
              paymentItems {
                ...on PlanPaymentItem {
                  amount
                  status
                  planId
                }
              }
              amounts {
                subTotal
                amount
                taxAmount
              }
              status
            }
            parts {
              from
              tof: to
              type
              TotalPerTopic {
                topicId
                lessonsPerWeek
                lockLessons
                numberFixedLessonsPerWeek
              }
            }
          }
        }
        lessonsGroups {
          ...on GroupLesson {
            id
            topic {
              id
            }
            service {
              id
            }
            educator {
              educatorUserId
            }
          }
        }
      }
    }`,
    {
      fetchPolicy: 'network-only'
    }
  )
  const getServiceById = (serviceId) => {
    return services
      ? services.find((service) => service.id === serviceId)
      : null
  }
  const modalClass = planInfo
    ? ((planInfo.status === 'sent' || planInfo.status === 'draft') ? 'pending-modal'
      : (planInfo.status === 'pause' || planInfo.status === 'cancel' ? 'pause-modal' : 'upcoming-modal'))
    : 'upcoming-modal'
  useEffect(() => {
    async function fetchGroupInfo(groupId) {
      try {
        await getGroupInfo({ variables: { planId: groupId } })
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        // eslint-disable-next-line
        alert(e.message)
      }
    }
    if (info) {
      fetchGroupInfo(info)
    }
  }, [info, getGroupInfo])
  useEffect(() => {
    if (data) {
      setPlanInfo(parsePlan(data.getGroupPlan))
      setCurrentService(getServiceById(data.getGroupPlan.serviceId))
    }
    // eslint-disable-next-line
  }, [data])
  const getStatus = (status) => {
    switch (status) {
      case 'draft':
        return (
          <div className='d-flex flex-row align-items-center mr-2'>
            <span className='mr-2 text-gray'>Draft</span>
            <span className='badge badge-pill point badge-gray'>&nbsp;</span>
          </div>
        )
      case 'cancel':
        return (
          <div className='d-flex flex-row align-items-center mr-2'>
            <span className='mr-2 text-gray'>Canceled</span>
            <span className='badge badge-pill point badge-dark'>&nbsp;</span>
          </div>
        )
      case 'sent':
        return (
          <div className='d-flex flex-row align-items-center mr-2'>
            <span className='mr-2 text-gray'>Pending</span>
            <span className='badge badge-pill point badge-error'>&nbsp;</span>
          </div>
        )
      case 'scheduled':
        return (
          <div className='d-flex flex-row align-items-center mr-2'>
            <span className='mr-2 text-gray'>Approved</span>
            <span className='badge badge-pill point badge-warning'>&nbsp;</span>
          </div>
        )
      default:
        return null
    }
  }
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'groupPlans.viewSingle',
      data: { isOpen: !isOpen }
    })
  }
  const editPlan = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.editGroupModal',
      data: { isOpen: true, planId: planInfo.id }
    })
    toggle()
  }
  const sendOne = (studentUserId) => {
    redirectToSendOnePlan(planInfo.childPlans.filter(e => e.studentUser.studentUserId === studentUserId)[0], () => {}, dispatch)
  }
  const sendAll = () => {
    redirectToSendAllPlan(planInfo, () => {}, dispatch)
  }
  const isSelected = (educatorUserId, topicId) => {
    if (planInfo.selectedEducators.filter(e => e.educatorUserId === educatorUserId && e.topicId === topicId).length > 0) {
      return true
    }
    return false
  }
  const isEdit = true

  const selectedEducators = !planInfo ? [] : planInfo.recommendedEducators.map(
    e => {
      return {
        ...e,
        used: e.teachingType === 'group' ? true : isSelected(e.educatorUserId, e.topicId)
      }
    }
  )
  const plans = !planInfo ? [] : planInfo.childPlans.map(e => { return e.id })

  return (
    <Modal
      className={`modal-${
          breakWidth === 'SM' ? 'botbar' : 'sidebar modal-desktop-half'
        } modal-colored ${modalClass}`}
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header mx-4 mt-4 p-0 mb-2'>
          {!planInfo ? (<Skeleton count={4} height={30} />)
            : (<><div className='d-flex flex-column px-4 pb-4 mt-4 '>
              <span
                style={
                  (planInfo && planInfo.status === 'cancel')
                    ? { textDecoration: 'line-through' }
                    : {}
                }
                className={`h2 mt-7 ${planInfo && (planInfo.status === 'pause' || planInfo.status === 'cancel') ? '' : 'text-light'}`}
              >{`${planInfo && planInfo?.name}`}
              </span>
              {planInfo && (
                <span className={`h45 ${planInfo && (planInfo.status === 'pause' || planInfo.status === 'cancel') ? '' : 'text-light'}`}>
                  {moment(planInfo.startTime).format('MMM D, YYYY')} to {moment(planInfo.endTime).format('MMM D, YYYY')}
                </span>
              )}
              <span className={`my-2 font-weight-light h45 ${planInfo && (planInfo.status === 'pause' || planInfo.status === 'cancel') ? '' : 'text-transparent'}`}>
                {`${planInfo && planInfo.testDate ? translate('plans.testDate') : translate('plans.fixedParts')} | ${planInfo && planInfo.location ? translate(`misc.location.${planInfo.location.type === 'hangouts' ? 'meets' : planInfo.location.type}`) : '-'}`}
              </span>

              <span>ID: {planInfo?.id}</span>
            </div>
            <span className='d-flex w-100 px-4'>
                <span className='flex-grow-1'>
                <IconLesson className={`${planInfo && (planInfo.status === 'pause' || planInfo.status === 'cancel') ? '' : 'text-light'} mr-2`} />
                {translate('misc.livePrep')}
              </span>
                <div className='m-0 p-0 d-flex align-items-end'>
                <div className='znk-table-dropdown dropdown-menu-width-content'>
                    <DropDown
                    direction='down'
                    fixedWidth
                    hoverEnabled={false}
                    options={[
                      {
                        component:
              <span
                className='h4 text-logo'
                onClick={editPlan}
              >{translate('plans.actions.edit')}
              </span>
                      }
                      ]}
                  >
                    <IconOptions size={20} className='m-0 p-0' style={{ transform: 'rotate(90deg)' }} />
                  </DropDown>
                  </div>
                <IconClose size={20} onClick={toggle} />
              </div>
              </span>
            </>)}
        </div>
      }
    >
      {
        planInfo
          ? (<div className='w-100'>
            <div className='d-flex flex-column px-0 mt-6 px-4 px-sm-6 px-md-7'>
              <h4 className='font-weight-bold py-1'>Weekly Schedule</h4>
              {
                planInfo && planInfo.PartsSchedule && planInfo.PartsSchedule.map((part, idx) => {
                  return (
                    <PartScheduleView key={idx} groupPart={part} idx={idx} currentService={currentService} educatorsBase={educatorsBase} />
                  )
                })
              }
            </div>
            <div className='w-100 pl-4 pl-sm-6 pl-md-7 my-5'>
              <hr />
            </div>
            <div className='d-flex flex-column px-0 mt-4 px-4 px-sm-6 px-md-7'>
              <h4 className='font-weight-bold py-1'>Educators</h4>
              {
                selectedEducators.filter(e => e.teachingType === 'group' && e.used === true).map(
                  educatorSel => {
                    return currentService && currentService.topics.map(
                      (topic, i) => {
                        const educator = educatorSel
                        return educatorSel.topicId === topic.id
                          ? <ListItem
                            className={`py-2 ${i === 0 ? 'mt-3' : ''}`}
                            Left={
                              <div className='d-flex align-items-center'>
                                <Avatar
                                  name={`${educator.firstName} ${educator.lastName}`}
                                  named={false}
                                  uri={null}
                                  size='avatar-medium'
                                />
                                <label className='ml-2 h4'>
                                  {`${educator.firstName} ${educator.lastName}`} <span className='text-muted'>{`(Group ${topic.name})`}</span>
                                </label>
                              </div>
                            }
                            Right={<IconArrowRight size={20} />}
                          />
                          : null
                      }
                    )
                  }
                )
              }
              {
                selectedEducators.filter(e => e.teachingType === 'individual' && e.used === true).map(
                  educatorSel => {
                    return currentService && currentService.topics.map(
                      (topic, i) => {
                        const educator = educatorSel
                        return educatorSel.topicId === topic.id
                          ? <ListItem
                            className={`py-2 ${i === 0 ? 'mt-3' : ''}`}
                            Left={
                              <div className='d-flex align-items-center'>
                                <Avatar
                                  name={`${educator.firstName} ${educator.lastName}`}
                                  named={false}
                                  uri={null}
                                  size='avatar-medium'
                                />
                                <label className='ml-2 h4'>
                                  {`${educator.firstName} ${educator.lastName}`} <span className='text-muted'>{`(Individual ${topic.name})`}</span>
                                </label>
                              </div>
                            }
                            Right={<IconArrowRight size={20} />}
                          />
                          : null
                      }
                    )
                  }
                )
              }
            </div>
            <div className='w-100 pl-4 pl-sm-6 pl-md-7 my-5'>
              <hr />
            </div>
            <div className='d-flex flex-column px-0 mt-4 px-4 px-sm-6 px-md-7'>
              <h4 className='font-weight-bold py-1'>Students</h4>
              <span className='my-2 font-weight-light text-gray'>{planInfo && Math.floor(planInfo.childPlans ? ((planInfo.childPlans.filter(e => e.status !== 'draft' && e.status !== 'sent').length * 100) / planInfo.childPlans.length) : 0)}% of students approved</span>
              <div className='w-100 barra-cont'>
                <hr className='barra-porc' style={{ width: `${planInfo && planInfo.childPlans ? ((planInfo.childPlans.filter(e => e.status !== 'draft' && e.status !== 'sent').length * 100) / planInfo.childPlans.length).toString() + '%' : '0%'}` }} />
              </div>
              {
                planInfo && planInfo.childPlans.map(
                  (estu, i) => {
                    return (
                      <ListItem
                        key={estu.studentUser.studentUserId}
                        className={`py-2 ${i === 0 ? 'mt-3' : ''}`}
                        Left={
                          <div className='d-flex align-items-center'>
                            <Avatar
                              name={`${estu.studentUser.firstName} ${estu.studentUser.lastName}`}
                              named={false}
                              uri={null}
                              size='avatar-medium'
                            />
                            <label className='ml-2 h4'>
                              {`${estu.studentUser.firstName} ${estu.studentUser.lastName}`}
                            </label>
                          </div>
                        }
                        Right={
                          <span className='d-flex w-100'>
                            {getStatus(estu.status)}
                            <div className='m-0 p-0 d-flex'>
                              <div className='znk-table-dropdown dropdown-menu-width-content m-0 p-0 align-items-end'>
                                <DropDown
                                  direction='down'
                                  fixedWidth
                                  hoverEnabled={false}
                                  options={['sent', 'draft'].includes(estu.status) ? [
                                    {
                                      component:
                                    <span
                                      className='h4 text-logo'
                                      onClick={() => { sendOne(estu.studentUser.studentUserId) }}
                                    >{translate('plans.actions.reminder')}
                                    </span>
                                    }
                                  ] : []}
                                >
                                  <IconOptions size={20} className='m-0 p-0' style={{ transform: 'rotate(90deg)' }} />
                                </DropDown>
                              </div>
                            </div>
                          </span>
                        }
                      />
                    )
                  }
                )
              }
            </div>
            <div className='w-100 pl-4 pl-sm-6 pl-md-7 my-5'>
              <hr />
            </div>
            <div className='d-flex flex-column px-0 mt-4 px-4 px-sm-6 px-md-7'>
              <h4 className='font-weight-bold py-1'>Plan breakdown</h4>
              <div className='d-flex justify-content-between my-3'>
                <h4 className='font-weight-bold py-1'>Total group price</h4>
                {/* plan.invoices.filter(e => !['draft', 'canceled'].includes(e.status)).reduce((sume, invoice) => sume + invoice.amount, 0) */}
                <h4 className='font-weight-bold py-1'>${planInfo && planInfo.childPlans
                  ? formatNumber(planInfo.childPlans.filter(d => d.status === 'scheduled').reduce((total, plan) => total + plan.invoices.filter(e => !['draft', 'canceled'].includes(e.status)).reduce((sume, invoice) => sume + invoice.paymentItems.filter(e => !['draft', 'canceled'].includes(e.status) && plans.includes(e.planId)).reduce((isume, item) => isume + item.amount, 0), 0), 0))
                  : formatNumber(0)}
                </h4>
              </div>
              <div className='w-100 barra-cont-flat'>
                <hr className='barra-porc-flat' style={{ width: `${planInfo && planInfo.childPlans ? ((planInfo.childPlans.filter(d => d.status === 'scheduled').reduce((total, plan) => total + plan.invoices.filter(e => ['paid'].includes(e.status)).reduce((sume, invoice) => sume + invoice.paymentItems.filter(ele => ['paid'].includes(ele.status) && plans.includes(ele.planId)).reduce((isume, item) => isume + item.amount, 0), 0), 0) * 100) / planInfo.childPlans.filter(d => d.status === 'scheduled').reduce((total, plan) => total + plan.invoices.filter(e => !['draft', 'canceled'].includes(e.status)).reduce((sume, invoice) => sume + invoice.paymentItems.filter(e => !['draft', 'canceled'].includes(e.status) && plans.includes(e.planId)).reduce((isume, item) => isume + item.amount, 0), 0), 0)).toString() + '%' : '0%'}` }} />
              </div>
              <span className='mt-4'>
                {translate('plans.modal.breakdowntext')}
              </span>
            </div>
            <div className='w-100 pl-4 pl-sm-6 pl-md-7 my-5'>
              <hr />
            </div>
            <div className='d-flex flex-column px-0 mt-4 px-4 px-sm-6 px-md-7'>
              <h4 className='font-weight-bold py-1'>Payment schedule</h4>
              <span className='mt-4'>
                {translate('plans.modal.scheduletext')}
              </span>
            </div>
            <div className='row px-0 mt-4 px-4 px-sm-6 px-md-7'>
              <Button
                color='primary'
                // activity={loadingEdit}
                // className={isEdit ? 'col-12' : 'col-9'}
                className='col-12'
                // label={isEdit ? translate('plans.modal.save') : translate('plans.modal.saveindraft')}
                label={translate('plans.actions.reminder')}
                onClick={sendAll}
                style={{ borderRadius: `${isEdit ? '16px' : '16px 0px 0px 16px'}` }}
              />
              {
                !isEdit &&
                  <Button
                    color='primary'
                    className='col-2'
                    // activity={loadingEdit}
                    label={
                      <DropDown
                        direction='up'
                        hoverEnabled={false}
                        classNameMenu='w-auto mb-3'
                        options={[{
                          component:
            <h4
              className='p-3'
              onClick={() => {}}
            >{translate('plans.modal.saveinsent')}
            </h4>
                        }]}
                      >
                        <IconArrowUp />
                      </DropDown>
                    }
                    style={{ borderRadius: '0px 16px 16px 0px', marginLeft: '1px' }}
                  />
              }
            </div>
          </div>)
          : (<div className='w-100 px-0 mt-6 px-4 px-sm-6 px-md-7'><Skeleton count={4} height={35} /></div>)
      }
    </Modal>
  )
}

export default ViewGroupPlan
